import {useToast} from "@chakra-ui/react"
import {dispatchAction} from "@dladio/app-bridge"
import {AutoCompleteTextField} from "@dladio/core-ui"
import {useForm, useLocalStore} from "@dladio/hooks"
import {Dialog, DialogFooter, PrimaryButton, Stack, TextField} from "@fluentui/react"
import _ from "lodash"
import {useDispatch} from "react-redux"
import {createAddress} from "../../../../store/actions/sap.actions"

const SO_KEY = 'so'
export const CREATE_ADDRESS_KEY = "create-shipping-address-dialog"

const CreateShippingAddressDialog = () => {

    const dispatch = useDispatch()
    const toast = useToast()
    const {select: soSelect} = useLocalStore(SO_KEY)
    const {select, setStore} = useLocalStore(CREATE_ADDRESS_KEY)
    const {form, formController} = useForm(CREATE_ADDRESS_KEY)

    let bpInfo = soSelect('bpInfo', soSelect)

    const createShippingAddress = async () => {
        setStore('isOpen', false)

        dispatchAction({
            action: 'loading',
            payload: true
        })

        let model: any = {
            ...form,
            "AddressType": "bo_ShipTo",
        }

        let {data, error}: any = await dispatch(createAddress(bpInfo("CardCode"), [model]) as any)

        if (!error) {
            dispatchAction({
                action: 'loading',
                payload: false
            })

            toast({
                title: 'Success [Create Address]',
                status: 'success',
                duration: 2500,
                isClosable: true,
            })
        } else {

            dispatchAction({
                action: 'loading',
                payload: false
            })

            toast({
                title: 'Failed [Create Address]',
                status: 'error',
                duration: 2500,
                isClosable: true,
            })
        }
    }

    return (
        <>
            {/* @ts-ignore */}
            <Dialog
                containerClassName={"lg:max-w-full lg:w-[700px]"}
                title={'New Shipping Address'}
                isOpen={select('isOpen')}
                isBlocking
                onDismiss={() => setStore('isOpen', false)}
            >
                <Stack horizontal style={{justifyContent: 'space-between', width: '100%'}} disableShrink>
                    <div>
                        <TextField value={_.get(form, 'Street')} onChange={formController} name={'Street'}
                                   label="Street"/>
                        <TextField value={_.get(form, 'Street No')} onChange={formController} name={'StreetNo'}
                                   label="Street No"/>
                        <TextField value={_.get(form, 'Block')} onChange={formController} name={'Block'}
                                   label="Block"/>
                        <TextField value={_.get(form, 'City')} onChange={formController} name={'City'}
                                   label="City"/>
                        <TextField value={_.get(form, 'Post Code')} onChange={formController} name={'ZipCode'}
                                   label="Post Code"/>
                        <TextField value={_.get(form, 'County')} onChange={formController} name={'County'}
                                   label="County"/>
                        <AutoCompleteTextField
                            label="State"
                            id={'State'}
                            onChange={formController}
                            options={{type: "erp", query: {name: 'b4f7a2dc33'}}}
                        />
                    </div>
                    <div>
                        <AutoCompleteTextField
                            keyValuePair={{key: 'Code', text: 'Name'}}
                            label="Country"
                            id={'Country'}
                            onChange={formController}
                            options={{type: "erp", query: {name: '460e2f689f'}}}
                        />
                        <TextField value={_.get(form, 'BuildingFloorRoom')} onChange={formController}
                                   name={'BuildingFloorRoom'}
                                   label="Building/Floor/Room"/>
                        <TextField value={_.get(form, 'AddressName')} onChange={formController}
                                   name={'AddressName'}
                                   label="Address Name"/>
                        <TextField value={_.get(form, 'AddressName2')} onChange={formController}
                                   name={'AddressName2'}
                                   label="Address Name 2"/>
                        <TextField value={_.get(form, 'AddressName3')} onChange={formController}
                                   name={'AddressName3'}
                                   label="Address Name 3"/>
                        <TextField value={_.get(form, 'GlobalLocationNumber')} onChange={formController}
                                   name={'GlobalLocationNumber'}
                                   label="Global Location Number"/>
                        <TextField value={_.get(form, 'U_AddressTo')} onChange={formController}
                                   name={'U_AddressTo'}
                                   label="Address To"/>
                    </div>
                </Stack>

                <DialogFooter>
                    <PrimaryButton onKeyDown={({key}: any) => (key === 'Enter') && createShippingAddress()}
                                   onClick={createShippingAddress}>
                        Save
                    </PrimaryButton>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default CreateShippingAddressDialog