import { localStoreThunk } from "@dladio/hooks"
import { erpMethodApi, SERVICE } from "@dladio/service"
import _ from "lodash"

const { setStore } = localStoreThunk("so")

export const getBpDetailsAction = (id: any) => {
    return async (dispatch: any) => {

        const { error, data } = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: SERVICE.EXEC_QUERY_METHOD,
            body: { name: 'bec0281ad8', id },
        }))

        if (!error && data) {
            dispatch(setStore("bpInfo", _.get(data, "message.bpInfo")))
        }
    }
}