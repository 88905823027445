import { TupleCard, TupleCardLine } from "@dladio/core-ui"
import { useLocalStore } from "@dladio/hooks"
import VisibleWrapper from "../VisibleWrapper/VisibleWrapper";

const BasicAccountInformation = () => {

    const { select } = useLocalStore('so')

    let bpInfo = select("bpInfo", select);

    return (
        <>
            <VisibleWrapper>
                <TupleCard title='Basic Account Information'>
                    <TupleCardLine title={'Account'} value={bpInfo("CardName")} />
                    <TupleCardLine title={'Account Group'} value={bpInfo("GroupName")} />
                    <TupleCardLine title={'Account Balance'} value={bpInfo("Balance")} />
                    <TupleCardLine title={'Orders'} value={`${bpInfo("Currency")} ${bpInfo("OpenOrderBalance")}`} />
                    <TupleCardLine title={'Payment Terms'} value={bpInfo("PaymentTerms")} />
                </TupleCard>
            </VisibleWrapper>
        </>
    )
}

export default BasicAccountInformation