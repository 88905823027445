import { useToast } from "@chakra-ui/react"
import { dispatchAction } from "@dladio/app-bridge"
import { AutoCompleteTextField } from "@dladio/core-ui"
import { useForm, useLocalStore } from "@dladio/hooks"
import { Dialog, DialogFooter, PrimaryButton, Stack, TextField } from "@fluentui/react"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { createContact } from "../../../../store/actions/sap.actions"

export const CREATE_CONTACT_KEY = "create-contact-dialog"
const user = 16
const CreateContactDialog = () => {

    const dispatch = useDispatch()
    const toast = useToast()
    const { select: soSelect } = useLocalStore('so')
    const { select, setStore } = useLocalStore(CREATE_CONTACT_KEY)
    const { form, formController } = useForm(CREATE_CONTACT_KEY)
    const { form: baseForm } = useForm()

    let bpInfo = soSelect('bpInfo', soSelect)

    const saveContact = async () => {
        setStore('isOpen', false)

        dispatchAction({
            action: 'loading',
            payload: true
        })

        let model: any = {
            ...form,
            Active: "tYES",
            Name: (form?.FirstName || '') + ' ' + (form?.LastName || '')
        }

        let { data, error }: any = await dispatch(createContact(_.get(baseForm, "SamplerAccount"), [model]) as any)

        if (!error) {
            dispatchAction({
                action: 'loading',
                payload: false
            })

            toast({
                title: 'Success [Create Contact]',
                status: 'success',
                duration: 2500,
                isClosable: true,
            })

        } else {

            dispatchAction({
                action: 'loading',
                payload: false
            })

            toast({
                title: 'Failed [Create Contact]',
                status: 'error',
                duration: 2500,
                isClosable: true,
            })
        }
    }

    return (
        <>
            {/* @ts-ignore */}
            <Dialog
                containerClassName={'lg:max-w-full lg:w-[650px]'}
                title={'Create New Contact'}
                isOpen={select('isOpen')}
                isBlocking
                onDismiss={() => setStore('isOpen', false)}
            >
                <Stack horizontal style={{ justifyContent: 'space-between' }} disableShrink>
                    <Stack>
                        <TextField onChange={formController}
                            required
                            value={_.get(form, 'FirstName')}
                            name={'FirstName'}
                            label="First Name" />
                        <TextField onChange={formController}
                            name={'LastName'}
                            label="Last Name" />
                        <TextField onChange={formController} name={'MobilePhone'} label="Mobile" type={'number'} />
                        <TextField onChange={formController} name={'E_Mail'} label="Email" />
                    </Stack>
                    <Stack>
                        <TextField onChange={formController}
                            name={'U_Position'}
                            label="Position" />
                        <AutoCompleteTextField
                            label="Contact Type"
                            id={'U_ContactType'}
                            defaultKey={_.get(form, 'U_ContactType')}
                            onChange={formController}
                            options={{ type: "erp", query: { name: 'a6e941493a' } }}
                        />
                        <TextField multiline rows={3} onChange={formController} name={'Remarks1'} label="Remarks" />

                    </Stack>

                </Stack>

                <DialogFooter>
                    <PrimaryButton onKeyDown={({ key }: any) => (key === 'Enter') && saveContact()} onClick={saveContact}>
                        Save
                    </PrimaryButton>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default CreateContactDialog