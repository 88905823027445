import { localStoreThunk, useAutoFit, useLocalStore } from "@dladio/hooks"
import { SERVICE, useDerive } from "@dladio/service"
import { multiSearch } from "@dladio/utils"
import { DefaultButton, DetailsHeader, DetailsList, DetailsListLayoutMode, IColumn, IDetailsHeaderProps, IDetailsListStyles, ISelection, SearchBox, Selection, SelectionMode, Stack } from "@fluentui/react"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

const config: any = {
    header_fields: [
        {
            label: 'Item Name',
            key: "ItemName",
            min_width: 80,
            max_width: 300
        },
        {
            label: 'Item Code',
            key: 'ItemCode',
            min_width: 80,
            max_width: 120
        }

    ]
}

export const SELECTED_SAMPLES = 'selected-samples'
let selection: ISelection = new Selection()


const { setStore: setSelectedSamples } = localStoreThunk(SELECTED_SAMPLES)

const ItemOrderHistoryTable = (
    {
        setPanelDismiss,
        quantityRef
    }: any) => {

    const dispatch = useDispatch()
    const [configs, setConfigs] = useState<any>(config)
    const { select, setStore } = useLocalStore('so')

    let bpInfo = select("bpInfo", select);

    useEffect(() => {
        setStore('selectedPreviousItem', null)
    }, [bpInfo("CardCode")])

    const getSelectedItem = (line: any) => {
        // setStore('selectedPreviousItem', _.get(line, [0, 'ItemCode']))
        // if (!_.isEmpty(line)) setPanelDismiss(false)
    }

    const { value: orderHistoryList } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: {
                name: "b586079c7a"
            }
        }, path: 'message'
    })

    return (
        <>
            <div className={'mx-2'}>
                <OrderHistoryCustomTable
                    selection={selection}
                    setPanelDismiss={setPanelDismiss}
                    config={configs}
                    quantityRef={quantityRef}
                    model={orderHistoryList ?? []} />
            </div>
        </>
    )
}

const OrderHistoryCustomTable = ({ model, config, selection, quantityRef, setPanelDismiss }: any) => {

    const dispatch = useDispatch()
    const [lines, setLines] = useState<any>([])
    const [columns, setColumns] = useState<any>([]) // need to set data
    const [ref, setAutoFit] = useAutoFit()
    const [groups, setGroups] = useState()

    useEffect(() => {
        setLines(model)
    }, [model])

    useEffect(() => {
        generateColumns()
    }, [model, config])

    useEffect(() => {
        generateGroup(lines)
    }, [lines])

    const quantityFocusHandler = () => {
        // quantityRef.current.focus()
        // quantityRef.current.select()
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName);
        return fieldContent
    }

    const generateColumns = () => {

        function _onColumnClick(event: React.MouseEvent<HTMLElement>, column: IColumn) {
            let isSortedDescending = column.isSortedDescending;
            if (column.isSorted) {
                isSortedDescending = !isSortedDescending;
            }

            let sortedItems = _copyAndSort(model, column.fieldName!, isSortedDescending);

            let columnList: any = columns.map((col: any) => {
                col.isSorted = col.key === column.key;

                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }

                return col;
            })

            setLines(sortedItems)
            setColumns(columnList)
        }

        let columns: IColumn[] = config.header_fields.map((line: any, index: any) => ({
            key: index,
            name: line.label,
            fieldName: line.key,
            page: line.page,
            dataType: line?.type == 'divider' ? null : line.data_type,
            meta: line?.field_meta,
            isResizable: true,
            minWidth: line?.min_width,
            maxWidth: line?.max_width,
            isSortedDescending: false,
            isSorted: false,
            styles: { root: { backgroundColor: '#f0f0f0' } },
            onColumnClick: _onColumnClick
        })
        )
        setColumns(columns)
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                // onRenderColumnHeaderTooltip={stickyHeader}
                />
            )
        }
        return null
    }
    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && model.length > 0 ? multiSearch(model, text) : model
        setLines(filteredLines)
    }

    const generateGroup = (list: any) => {

        let uniqueArray = list.reduce((a: any, d: any) => {
            if (a.indexOf(_.get(d, "ItmsGrpNam")) === -1) {
                a.push(_.get(d, "ItmsGrpNam"));
            }
            return a;
        }, []);

        let indexes = uniqueArray.map((_line: any) => ({
            key: _line,
            name: _line,
            count: list.filter((line: any) => _.get(line, "ItmsGrpNam") == _line).length,
            level: 0,
            isCollapsed: true
        }))

        let groupedList: any = indexes.reduce((a: any, d: any) => {
            let startIndex = 0
            a.length > 0 && (startIndex = a[a.length - 1].startIndex + a[a.length - 1].count)
            a.push({ ...d, startIndex: startIndex });
            return a
        }, []);

        setGroups(groupedList)
    }

    let className = "overflow-auto h-lg"

    return (
        <Stack className={'bg-white rounded-2'}>
            {/*SEARCH BOX*/}
            {/*<div className={'d-flex flex-wrap'}>*/}
            {/*    <SearchBox onChange={searchTextChangeHandler}*/}
            {/*               className="w-25 m-2 rounded-2"*/}
            {/*               placeholder="Search"*/}
            {/*    />*/}
            {/*</div>*/}
            <div>
                <SearchBox onChange={searchTextChangeHandler} className="w-[280px] m-2 rounded-2" placeholder="Search" />
                <DefaultButton className="mx-2" onClick={() => {
                    dispatch(setSelectedSamples(selection.getSelection()) as any)
                    setPanelDismiss(false)
                }}>Add Selection</DefaultButton>
            </div>

            <div ref={ref} className={className}>
                <DetailsList
                    items={lines}
                    columns={columns}
                    selection={selection}
                    compact={true}
                    styles={gridStyles}
                    groups={groups}
                    onActiveItemChanged={quantityFocusHandler}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    // onRenderRow={_onRenderRow}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.multiple}
                />
                {/* <NoDataFound visible={(lines?.length == 0)}/> */}
            </div>
        </Stack>
    )
}
const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

export default ItemOrderHistoryTable