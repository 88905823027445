import { useLocalStore } from "@dladio/hooks"
import { MessageBar, MessageBarType, Text } from "@fluentui/react"

const CreditLimitAlert = () => {

    const { select } = useLocalStore('so')

    let bpInfo = select('bpInfo', select)

    return (
        <>
            {bpInfo('CreditLimitExceeded') ?
                <div className="px-2 pb-2 bg-[#f0f0f0]">
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        dismissButtonAriaLabel="Close">
                        <Text>Credit Limit exceeded</Text>
                    </MessageBar>
                </div> : null
            }
        </>
    )
}

export default CreditLimitAlert