import { useLocalStore } from "@dladio/hooks"
import { Panel } from "@fluentui/react"
import ItemOrderHistoryTable from "./components/ItemOrderHistoryTable"

const ItemHistoryPanel = () => {
    const { select: panelSelect, setStore: setPanelStore } = useLocalStore('item-history-panel')

    const dismissPanel = (value: any) => {
        setPanelStore('isOpen', value)
    }

    return (
        <>
            <Panel
                type={4}
                headerText="Sampler Items"
                isOpen={panelSelect('isOpen')}
                isLightDismiss={true}
                onDismiss={() => dismissPanel(false)}
                closeButtonAriaLabel="Close"
            >
                <ItemOrderHistoryTable
                    // quantityRef={quantityRef}
                    setPanelDismiss={dismissPanel}
                />
            </Panel>
        </>
    )
}

export default ItemHistoryPanel