import { TupleCard, TupleCardLine } from "@dladio/core-ui"
import { useForm, useLocalStore } from "@dladio/hooks"
import _ from "lodash"
import { additionalExpensesFactory, getOrderProcessingFee, getTotal, orderSummaryFactory } from "../../actions/Calculations/orderProcessingCalculations"
import { MODES_OF_DELIVERY } from "../../data/mode-of-delivery"
import VisibleWrapper from "../VisibleWrapper/VisibleWrapper"

const Summery = () => {

    const { form } = useForm()
    const { select } = useLocalStore('so')

    let bpInfo = select('bpInfo', select)

    let orderSummary = orderSummaryFactory(select("finalSoLines"));
    let additionalExpenses = additionalExpensesFactory(MODES_OF_DELIVERY, _.get(form, 'modes_of_delivery'))
    let orderProcessingFee = getOrderProcessingFee(_.get(form, 'shippingAddress'), bpInfo("IsOrderProcessingFeeApplied"), orderSummary, _.get(form, 'modes_of_delivery'))

    return (
        <>
            <VisibleWrapper>
                <TupleCard title='Summary'>
                    <TupleCardLine title={'Total Item'} value={select("finalSoLines")?.length ?? 0} />
                    <TupleCardLine title={'Total Before Discount'} value={`${bpInfo("Currency")} ${getTotal(orderSummary, additionalExpenses, orderProcessingFee).getSubTotalBeforeDiscount().toFixed(2)}`} />
                    <TupleCardLine title={'Total Discount'} value={`${bpInfo("Currency")} ${orderSummary.totalDiscount().toFixed(2)}`} />
                    <TupleCardLine title={'Freight'} value={`${bpInfo("Currency")} ${additionalExpenses.getFreight().toFixed(2)}`} />
                    <TupleCardLine title={'Tax'} value={`${bpInfo("Currency")} ${getTotal(orderSummary, additionalExpenses, orderProcessingFee).getTotalTax().toFixed(2)}`} />
                    <TupleCardLine title={'Total'} value={`${bpInfo("Currency")} ${getTotal(orderSummary, additionalExpenses, orderProcessingFee).getSubTotal().toFixed(2)}`} />
                </TupleCard>
            </VisibleWrapper>
        </>
    )
}

export default Summery