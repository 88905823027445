
import { AppBridgeProvider } from "@dladio/app-bridge";
import { CoreUIProvider } from "@dladio/core-ui";
import ConfirmDialog from "./components/common/dialogs/ConfiemationDialog/ConfirmationDialog";
import CreateContactDialog from "./components/pages/components/CreateContactDialog/CreateContactDialog";
import CreateShippingAddressDialog from "./components/pages/components/CreateShippingAddressDialog/CreateShippingAddressDialog";
import ItemHistoryPanel from "./components/pages/components/ItemHistoryPanel/ItemHistoryPanel";
import Home from "./components/pages/Home";

function App() {
  return (
    <AppBridgeProvider apiKey="sampler-order">
      <CoreUIProvider>
        <Home />
        <ConfirmDialog />
        <ItemHistoryPanel />
        <CreateShippingAddressDialog />
        <CreateContactDialog />
      </CoreUIProvider >
    </AppBridgeProvider>
  );
}

export default App;
