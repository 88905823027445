import * as yup from 'yup';

let salesOrderSchema = yup.object().shape({
    U_OrderChannel: yup.string().required(),
    NumAtCard: yup.string().required(),
    ContactPersonCode: yup.string().required(),
    U_OrderChannelDetails: yup
        .string()
        .when('U_OrderChannel',
            {
                is: 'Email',
                then: yup.string().required('Must enter email address')
            })
});

export {salesOrderSchema}