import { TupleCard, TupleCardLine } from "@dladio/core-ui"
import { useForm, useLocalStore } from "@dladio/hooks";
import _ from "lodash";
import { additionalExpensesFactory, getOrderProcessingFee, orderSummaryFactory } from "../../actions/Calculations/orderProcessingCalculations";
import { MODES_OF_DELIVERY } from "../../data/mode-of-delivery";
import VisibleWrapper from "../VisibleWrapper/VisibleWrapper";

const ExtraChargesAndTradingTerms = () => {

    const { form } = useForm()
    const { select } = useLocalStore('so')

    let bpInfo = select("bpInfo", select);

    let orderSummary = orderSummaryFactory(select("finalSoLines"));
    let additionalExpenses = additionalExpensesFactory(MODES_OF_DELIVERY, _.get(form, "modes_of_delivery"))
    let orderProcessingFee = getOrderProcessingFee(_.get(form, 'shippingAddress'), bpInfo("IsOrderProcessingFeeApplied"), orderSummary, _.get(form, 'modes_of_delivery'))

    return (
        <>
            <VisibleWrapper>
                <TupleCard title='Extra Charges & Trading Terms'>
                    <TupleCardLine title={'Order Processing Fee'} value={`${bpInfo("Currency")} ${orderProcessingFee.toFixed(2)}`} />
                    <TupleCardLine title={'Freight Charges'} value={`${bpInfo("Currency")} ${additionalExpenses.getFreight().toFixed(2)}`} />
                </TupleCard>
            </VisibleWrapper>
        </>
    )
}

export default ExtraChargesAndTradingTerms