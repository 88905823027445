import { useAppBridgeSelector } from "@dladio/app-bridge"
import { AutoCompleteTextField, Stack } from "@dladio/core-ui"
import { localStoreThunk, useForm } from "@dladio/hooks"
import { erpMethodApi, SERVICE, useDerive } from "@dladio/service"
import { Text, TextField } from "@fluentui/react"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { getBpDetailsAction } from "../../actions"
import { CREATE_CONTACT_KEY } from "../CreateContactDialog/CreateContactDialog"

const { setStore: setContactStore } = localStoreThunk(CREATE_CONTACT_KEY)

const AccountInformationForm = () => {

    const dispatch = useDispatch()
    const { form, formController, setForm } = useForm()
    const { setForm: setContactForm } = useForm(CREATE_CONTACT_KEY)
    const { model } = useAppBridgeSelector()
    
    const {value: user} = useDerive('exec',{
        options: {
            method: "dlad_core.api.get_current_user",
            body: {}
        },
        path: 'message.sap_id'
    })    
    
    //create contact
    const [visibleCreateContact, setVisibleCreateContact] = useState(false)
    const [typedContactName, setTypedContactName] = useState()


    const { value: SamplerAccount } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: {
                name: 'da4da1d27a',
                id: user
            }
        },
        path: 'message.value'
    })

    useEffect(() => {
        setForm("SamplerAccount", SamplerAccount)
    }, [SamplerAccount])

    useEffect(() => {
        setForm('bpCode', _.get(model, 'params.id'))
    }, [_.get(model, 'params.id')])

    useEffect(() => {
        dispatch(getBpDetailsAction(_.get(form, 'bpCode')) as any)
    }, [_.get(form, 'bpCode')])

    return (
        <>
            <Stack className={"gap-2"}>
                <AutoCompleteTextField
                    keyValuePair={{ key: 'CardCode', text: 'CardName' }}
                    defaultKey={_.get(form, 'bpCode')}
                    label={"Account"}
                    required
                    id={'bpCode'}
                    onChange={formController}
                    options={{
                        type: "erp",
                        query: { name: '2a7afc9eb7' }
                    }}
                />
                <div className="relative inline-block">
                    <AutoCompleteTextField
                        label={"Contact"}
                        required
                        id={'contact_person_code'}
                        onValueNotFound={(text: any) => {

                            if (text) {
                                setVisibleCreateContact(true)
                                setTypedContactName(text)
                                setContactForm("FirstName", text)
                                setTimeout(() => {
                                    document?.getElementById('create-contact-person')?.focus()
                                }, 100);
                            }
                        }}
                        onChange={formController}
                        options={{
                            type: "erp",
                            query: { name: 'f89b1145b2', id: _.get(form, "SamplerAccount") }
                        }}
                    />
                    {
                        visibleCreateContact ?
                            <div
                                onKeyDown={({ key }: any) => (key === 'Enter') && dispatch(setContactStore('isOpen', true) as any)}
                                onClick={() => dispatch(setContactStore('isOpen', true) as any)} id={'create-contact-person'}
                                tabIndex={0} onBlur={() => setVisibleCreateContact(false)}
                                className="origin-top-left px-2 absolute z-50 left-0 w-full max-h-[180px] shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none overflow-auto cursor-pointer">
                                <div className="flex flex-col">
                                    <Text className="font-bold" variant={'xSmall'}>Create Contact</Text>
                                    <Text variant={'xSmall'}>{typedContactName}</Text>
                                </div>
                            </div> : null
                    }
                </div>

                <TextField
                    required
                    name={"customer_ref_no"}
                    onChange={formController}
                    label={"Customer Ref.No"} />
            </Stack>
        </>
    )
}

export default AccountInformationForm