import { AutoCompleteTextField, Box, Stack } from "@dladio/core-ui"
import { useForm, useLocalStore } from "@dladio/hooks";
import { DatePicker, TextField } from "@fluentui/react"
import _ from "lodash";
import { useEffect } from "react";
import VisibleWrapper from "../VisibleWrapper/VisibleWrapper";

const ShippingInformationForm = () => {

    const { select } = useLocalStore('so')

    let bpInfo = select("bpInfo", select);

    const { form, formController, setForm } = useForm()

    useEffect(() => {
        setForm('modes_of_delivery', bpInfo("ModeOfDelivery"))
        setForm('mode_of_delivery_trnspCode', bpInfo("ModeOfDeliveryTrnspCode"))
    }, [bpInfo('CardCode')])

    const modesOfDeliveryHandler = (id: any, key: any, text: any) => {
        setForm('mode_of_delivery_trnspCode', key)
        setForm('modes_of_delivery', text)
    }

    return (
        <>
            <VisibleWrapper>
                <Box title="Packing, Delivery & Shipping Information">
                    <Stack className={'gap-2'}>
                        <DatePicker
                            value={new Date()}
                            style={{ width: "184px" }}
                            label={"Packing Date"} />
                        <DatePicker
                            value={new Date()}
                            style={{ width: "184px" }}
                            label={"Shipping Date"} />

                        <AutoCompleteTextField
                            required
                            label={"Modes of Delivery"}
                            id={'modes_of_delivery'}
                            defaultKey={_.get(form, 'modes_of_delivery')}
                            onChange={modesOfDeliveryHandler}
                            options={{
                                type: "erp",
                                query: { name: '0f46e124ea' }
                            } || []}
                        />
                    </Stack>
                    <TextField
                        className={"w-full"}
                        name={"remarks"}
                        onChange={formController}
                        label={"Remarks"}
                        multiline rows={3}
                    />
                </Box>
            </VisibleWrapper>
        </>
    )
}

export default ShippingInformationForm