import { Stack } from "@dladio/core-ui"
import { useForm } from "@dladio/hooks"
import { TextField } from "@fluentui/react"
import _ from "lodash"
import { useEffect } from "react"

const OrderInformationForm = () => {

    const { form, formController, setForm } = useForm()

    useEffect(() => {
        setForm("order_channel", "Other")
    }, [])

    return (
        <>
            <Stack className={"gap-2"}>

                <TextField label="Order Channel" value={_.get(form, 'order_channel')} required disabled />
                <TextField
                    required={_.get(form, 'order_channel') == 'Email' ? true : false}
                    style={{ width: "184px" }}
                    name={"contact_details"}
                    onChange={formController}
                    label={"Contact Details"} />
            </Stack>
        </>
    )
}

export default OrderInformationForm