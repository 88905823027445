import { useForm } from "@dladio/hooks"
import _ from "lodash"

const VisibleWrapper = ({ children }: any) => {
    const { form } = useForm()

    let bpCode = _.get(form, 'bpCode')

    return (
        <>
            {bpCode ? children : null}
        </>
    )

}

export default VisibleWrapper