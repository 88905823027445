import { Stack } from "@dladio/core-ui"
import VisibleWrapper from "../VisibleWrapper/VisibleWrapper"
import BillTo from "./components/BillTo"
import ShipTo from "./components/ShipTo"

const Address = () => {
    return (
        <>
            <VisibleWrapper>
                <Stack>
                    <BillTo />
                    <ShipTo />
                </Stack>
            </VisibleWrapper>

        </>
    )
}

export default Address